import * as React from "react";
import { AuthenticatedFrame } from "../../../AuthenticatedFrame/AuthenticatedFrame";
import { SEO } from "../../../SEO/SEO";
import { SoundContextProvider } from "../Game/Game/SoundContext";
import { useTranslation } from "react-i18next";
import { ReplaysContent } from "./ReplaysContent";
import { SSRGuard } from "../../../SSRGuard/SSRGuard";

const Replays: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const title = t("gameReplay");
    const description = t("gameReplay");

    return (
        <SoundContextProvider>
            <SEO description={description} title={title} />
            <AuthenticatedFrame title={t("gameReplay")}>
                <SSRGuard>
                    <ReplaysContent />
                </SSRGuard>
            </AuthenticatedFrame>
        </SoundContextProvider>
    );
};

export default Replays;
